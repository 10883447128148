/**
 * @file global/_mixins.scss
 *
 * \brief Mixins are helpful snippets that let us not repeat ourselves
 *
 * Mixins output css and style rules. Don't use a mixin where you could use
 * @extend instead.
 */

///
// Many of our fonts and letter-spacing are handled by pt's
// tracking() exists in _functions.scss, converts tracking to letter-spacing
///
@mixin tracking($font-size, $tracking: 0) {
  letter-spacing: tracking($font-size, $tracking);
}

///
// Use the print terminology for "line-height"
///
@mixin leading($leading: 1) {
  line-height: $leading;
}

///
// Combine a lot of print terms together in one CSS declaration
///
@mixin print-size-tracking($font-size, $tracking: 0) {
  @include tracking($font-size, $tracking);
  font-size: $font-size;
}

@mixin cl-box-shadow {
  @include box-shadow(0 0 12px #ccc);
}

@mixin cl-standard-link($color: $color-cl-green-2, $hover-darken: 10%, $hover-color: false) {
  color: $color;
  font-family: $base-link-font-family;
  @if $cr19 == true {
    color: $cr19-text-grey;
  }
  @if ($hover-darken or $hover-color) {
    &:hover {
      @if ($hover-color) {
        color: $hover-color;
        @if ($cr19 == true) {
          color: $cr19-text;
        }
      } @else if ($hover-darken == bold) {
        font-weight: bold;
      } @else if ($hover-darken == true and $color == $color-text-black) {
        //font-weight: bold;
      } @else if ($cr19 == true) {
        color: $cr19-text;
      } @else {
        color: darken($color, $hover-darken);
      }
    }
  }
  &,
  &:hover {
    text-decoration: none;
  }
}

@mixin cl-related-content-item {
  .related_content_item & {
    clear: both;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 0;
    border-top: 1px solid #dbdbdb;
    @content;
    // resets for any HTML content added by CMS users, directly into the heading,
    // so that the margin's automatically put on <p> and <span> tags (if any)
    // are removed in this context
    h1,
    h2 {
      p,
      span {
        margin-top: 0;
        line-height: inherit;
      }
    }
  }
}

// Checkout Implementation
@mixin checkout_wrapper_margin {
  margin: 69px 90px;
}

@mixin view_cart_border_styles {
  border: 0px;
  border-top: 1px solid $color-light-grey;
}

@mixin header(
  $font-size: $header1,
  $font-family: $font-family-light,
  $font-weight: $font-weight-normal,
  $color: $lightBlack_color,
  $text-transform: none
) {
  font-size: $font-size;
  font-family: $font-family;
  font-weight: $font-weight;
  color: $color;
  text-transform: $text-transform;
}

@mixin link(
  $font-size: $header1,
  $font-family: $font-family-light,
  $font-weight: $font-weight-normal,
  $color: $lightBlack_color,
  $text-decoration: none,
  $text-transform: none
) {
  font-size: $font-size;
  font-family: $font-family;
  font-weight: $font-weight;
  text-decoration: $text-decoration;
  color: $color;
  text-transform: $text-transform;
}

// Utilities and Cross-Browser Workarounds
@mixin cl-clearfix {
  &:after {
    visibility: hidden;
    display: block;
    content: '';
    clear: both;
    height: 0;
  }
  * html & {
    zoom: 1;
  } // IE6
  *:first-child + html & {
    zoom: 1;
  } // IE7
}

@mixin breakpoint($size) {
  @media only screen and (max-width: $size) {
    @content;
  }
}

@mixin start-breakpoint($size) {
  @media all and (min-width: $size) {
    @content;
  }
}

@mixin breakpoint-range($start-from, $stop-at) {
  @media only screen and (max-width: $stop-at) and (min-width: $start-from) {
    @content;
  }
}

@mixin pre-order-btn-styles {
  background: $color-white;
  color: $color-black;
  &:hover {
    background: $color-white;
    color: $color-black;
  }
}

@mixin arrow-next($size, $color) {
  cursor: pointer;
  display: block;
  width: $size;
  height: $size;
  z-index: 1;
  transition: all 0.2s linear;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 70%;
    height: 5%;
    top: 27%;
    left: 55%;
    background: $color;
    z-index: 2;
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all 0.2s linear;
  }
  &:after {
    z-index: 3;
    top: 73%;
    left: 55%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

@mixin arrow-prev($size, $color) {
  cursor: pointer;
  display: block;
  width: $size;
  height: $size;
  z-index: 1;
  transition: all 0.2s linear;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 70%;
    height: 5%;
    top: 73%;
    left: -25%;
    background: $color;
    z-index: 2;
    transform: translate(50%, 50%) rotate(45deg);
    transition: all 0.2s linear;
  }
  &:after {
    z-index: 3;
    top: 26%;
    left: -25%;
    transform: translate(50%, 50%) rotate(-45deg);
  }
}

@mixin swap_direction($type, $v) {
  @if length($v) == 4 {
    @if $text-direction == rtl {
      #{$type}: nth($v, 1) nth($v, 4) nth($v, 3) nth($v, 2);
    } @else {
      #{$type}: $v;
    }
  } @else {
    #{$type}: $v;
  }
}

@mixin outer-wrap {
  @include box-sizing(border-box);
  margin: 0 auto;
  max-width: 960px;
  padding: 0;
}

// 2019 Redesign
// New styleguide / typography mixins - applied in helper classes

@mixin base-title(
  $font-size,
  $font-size-large-up,
  $font-family: $helvetica-light-font-family
) {
  font-family: $font-family;
  font-size: $font-size;
  letter-spacing: 0;
  line-height: 1;
  margin: 0;
  color: $color-cl-light-black;
  @media #{$cr19-typography-large-up} {
    font-size: $font-size-large-up;
  }
}

// Title - xxl
@mixin text-title--xxl {
  @include base-title(2.25rem, 5rem);
}

// Title - xl
@mixin text-title--xl {
  @include base-title(1.875rem, 3.75rem);
}
// Title - large
@mixin text-title--large {
  @include base-title(1.625rem, 3rem);
}

// Title - med
@mixin text-title--med {
  @include base-title(1.375rem, 2.25rem);
}

@mixin text-title--med--no-xl {
  // used for products - only editorial blocks need xl
  font-family: $helvetica-bold-font-family;
  font-size: 22px;
  letter-spacing: 0;
  line-height: get-line-height(22px, 24px);
  margin: 0;
  @media #{$cr19-large-up} {
    font-size: 24px;
    line-height: get-line-height(24px, 32px);
  }
}
// Title - small
@mixin text-title--small {
  @include base-title(1.25rem, 1.875rem);
}

// Title - X - small
@mixin text-title--x-small {
  @include base-title(1.125rem, 1.5rem, $font-family: $helvetica-regular-font-family);
  line-height: 1.18rem;
}

@mixin text-body-base(
  $font-family: $helvetica-regular-font-family,
  $font-size: 1rem,
  $line-height: 1.5rem,
  $font-size-large-up: $font-size,
  $line-height-large-up: $line-height,
  $color: $color-cl-light-black,
  $text-transform: none
) {
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  margin: 0;
  color: $color;
  letter-spacing: 0;
  text-transform: $text-transform;
  @media #{$cr19-typography-large-up} {
    font-size: $font-size-large-up;
    line-height: $line-height-large-up;
  }
}

// Body text - large
@mixin text-body-text--large($font-family: $helvetica-regular-font-family) {
  @include text-body-base($font-family, $font-size: 1.125rem, $line-height: 1.18rem);
}
// Body text - default
@mixin text-body-text($font-family: $helvetica-regular-font-family) {
  @include text-body-base($font-family, $font-size: 1rem, $line-height: 1.5rem);
}

@mixin text-body-text--small($font-family: $helvetica-regular-font-family) {
  @include text-body-base(
    $font-family,
    $font-size: 0.8125rem,
    $line-height: 1.5rem,
    $font-size-large-up: 0.875rem,
    $line-height-large-up: 1.3rem
  );
}

@mixin text-body-text--no-xl {
  // used for products and nav - only editorial blocks need xl
  font-family: $helvetica-regular-font-family;
  font-size: 16px;
  letter-spacing: 0;
  line-height: get-line-height(16px, 24px);
  margin: 0;
}

@mixin text-special--callout($font-family: $helvetica-regular-font-family) {
  @include text-body-base(
    $font-family,
    $font-size: 1.25rem,
    $line-height: 1.18rem,
    $font-size-large-up: 1.5rem
  );
}

@mixin text-special--large($font-family: $helvetica-regular-font-family) {
  @include text-body-base(
    $font-family,
    $font-size: 1.125rem,
    $line-height: 1.18rem,
    $font-size-large-up: 1.25rem
  );
}

@mixin text-special-body(
  $font-family: $helvetica-regular-font-family,
  $txt-transform: none
) {
  @include text-body-base(
    $font-family,
    $font-size: 0.8rem,
    $line-height: 1.3rem,
    $font-size-large-up: 0.875rem,
    $text-transform: $txt-transform
  );
}

// Body text - fine
@mixin text-body-text--fine($font-family: $helvetica-regular-font-family) {
  @include text-body-base(
    $font-family,
    $font-size: 0.69rem,
    $line-height: 1.4rem,
    $color: $color-cl-dark-grey
  );
}

// Body text - tag style
@mixin text-body-text--tags {
  font-family: $base-bolder-font-family;
  letter-spacing: 0.08em;
  line-height: get-line-height(11px, 16px);
  margin: 0;
  text-transform: uppercase;
  @if $badge-bold {
    color: $color-white;
    font-size: 8px;
  } @else {
    color: inherit;
    font-size: 11px;
  }
}

// 2019 Redesign
// New styleguide / Button mixins - applied in helper classes
// Button base
@mixin elc-button-base {
  cursor: pointer;
  display: inline-block;
  height: 45px;
  padding: 0 10px;
  line-height: get-line-height(18px, 45px);
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  @media #{$cr19-large-up} {
    min-width: 220px;
    width: auto;
  }
}
@mixin elc-button {
  @include text-special-body;
  @include elc-button-styles(
    $txt-color: $cr19-text,
    $bg-color: transparent,
    $bg-color-on-hover: transparent,
    $bg-color-on-focus: transparent,
    $border-color: transparent,
    $border-color-on-hover: transparent,
    $border-color-on-focus: transparent
  );
}
// @todo remove after react component cleanup
// temporary fixes to address presentational styles coming directly from react components
@mixin elc-button-component-overrides {
  letter-spacing: unset;
  padding: 0;
  border-radius: 0;
}

@mixin elc-button-styles(
  $txt-color: $cr19-text,
  $bg-color: $color-white,
  $bg-color-on-hover: $color-white,
  $bg-color-on-focus: $color-white,
  $border-color: $color-black,
  $border-color-on-hover: $color-gray-dusty,
  $border-color-on-focus: $color-grey
) {
  @include elc-button-base;
  color: $txt-color;
  background-color: $bg-color;
  border: 1px solid $border-color;
  line-height: get-line-height(14px, 45px);
  @media #{$cr19-large-up} {
    line-height: get-line-height(15px, 45px);
  }
  &:hover {
    border: 1px solid $border-color-on-hover;
    background-color: $bg-color-on-hover;
  }
  &:active {
    border: 1px solid $border-color-on-focus;
    background-color: $bg-color-on-focus;
  }
}
// Light Button
@mixin elc-button--light {
  @include text-special-body;
  @include elc-button-styles(
    $txt-color: $cr19-text,
    $bg-color: transparent,
    $bg-color-on-hover: transparent,
    $bg-color-on-focus: transparent,
    $border-color: $color-black,
    $border-color-on-hover: $color-gray-dusty,
    $border-color-on-focus: $color-grey
  );
}
// Light Button disabled
@mixin elc-button--light-disabled {
  @include text-special-body;
  @include elc-button-styles(
    $txt-color: $color-light-grey,
    $bg-color: transparent,
    $bg-color-on-hover: transparent,
    $bg-color-on-focus: transparent,
    $border-color: $color-grey,
    $border-color-on-hover: $color-grey,
    $border-color-on-focus: $color-grey
  );
}
// Dark Button
@mixin elc-button--dark {
  @include text-special-body;
  @include elc-button-styles(
    $txt-color: $color-white,
    $bg-color: $color-black,
    $bg-color-on-hover: $cr19-bg-dark,
    $bg-color-on-focus: $cr19-bg-med,
    $border-color: $color-black,
    $border-color-on-hover: $cr19-bg-dark,
    $border-color-on-focus: $cr19-bg-med
  );
}
// Dark Button disabled
@mixin elc-button--dark-disabled {
  @include text-special-body;
  @include elc-button-styles(
    $txt-color: $color-white,
    $bg-color: $color-grey,
    $bg-color-on-hover: $color-grey,
    $bg-color-on-focus: $color-grey,
    $border-color: $color-grey,
    $border-color-on-hover: $color-grey,
    $border-color-on-focus: $color-grey
  );
}
// Dark Button - Light On Hover
@mixin elc-button--dark--light-hover {
  @include text-special-body;
  @include elc-button-styles(
    $txt-color: $color-white,
    $bg-color: $color-black,
    $bg-color-on-hover: $color-white,
    $bg-color-on-focus: $color-white,
    $border-color: $color-black,
    $border-color-on-hover: $color-black,
    $border-color-on-focus: $color-black
  );
  &:hover,
  &:active {
    color: $color-black;
  }
}
// Inverted Button
@mixin elc-button--inverted {
  @include text-special-body;
  @include elc-button-styles(
    $txt-color: $cr19-text,
    $bg-color: $color-white,
    $bg-color-on-hover: $color-white-75opacity,
    $bg-color-on-focus: $color-white-6opacity,
    $border-color: $color-white,
    $border-color-on-hover: transparent,
    $border-color-on-focus: transparent
  );
}
// Inverted Button
@mixin elc-button--inverted-disabled {
  @include text-special-body;
  @include elc-button-styles(
    $txt-color: $color-light-grey,
    $bg-color: $color-white-6opacity,
    $bg-color-on-hover: $color-white-6opacity,
    $bg-color-on-focus: $color-white-6opacity,
    $border-color: transparent,
    $border-color-on-hover: transparent,
    $border-color-on-focus: transparent
  );
}
// Grey Button disabled
@mixin elc-button--grey-disabled {
  @include text-special-body;
  @include elc-button-styles(
    $txt-color: $cr19-text-grey,
    $bg-color: $color-light-white,
    $bg-color-on-hover: $color-light-white,
    $bg-color-on-focus: $color-light-white,
    $border-color: $color-light-grey,
    $border-color-on-hover: $color-light-grey,
    $border-color-on-focus: $color-light-grey
  );
}

// 2019 Redesign
// New styleguide / Link style mixins - applied in helper classes
// Variants are meant to be loaded after base mixin
// Link base
@mixin text-link-style(
  $txt-color: $cr19-text,
  $txt-uppercase: true,
  $default-underline: true,
  $underline-on-hover-color: $cr19-text,
  $underline-on-focus-color: $color-grey,
  $txt-underline-offset: $default-underline-offset
) {
  color: $txt-color;
  position: relative;
  text-decoration: underline;
  text-decoration-color: transparent;
  text-decoration-thickness: 1px;
  text-underline-offset: 1px;
  transition: 0.3s;
  @if $txt-uppercase {
    text-transform: uppercase;
  }
  @if $default-underline {
    text-decoration-color: $txt-color;
    text-underline-offset: $txt-underline-offset;
  }
  &:hover {
    text-decoration-color: $underline-on-hover-color;
  }
  &:focus {
    text-decoration-color: $underline-on-focus-color;
  }
  &:hover,
  &:focus {
    text-underline-offset: $txt-underline-offset;
  }
  &:active,
  &:visited {
    color: $txt-color;
  }
}

@mixin text-link {
  color: $cr19-text;
  font-family: $nitti-regular-font-family;
  font-size: 1rem;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    text-decoration: none;
  }
  &:active,
  &:visited {
    color: $cr19-text;
    text-decoration: none;
  }
}

// Link - Style 1 - Dark with underline
@mixin text-link--style-1 {
  @include text-special-body;
  @include text-link-style(
    $txt-color: $cr19-text,
    $txt-uppercase: true,
    $default-underline: true,
    $underline-on-hover-color: $cr19-text-grey,
    $underline-on-focus-color: $color-grey,
    $txt-underline-offset: 0.313rem
  );
}
// Link - Style 2 - Dark, underline on hover
@mixin text-link--style-2 {
  @include text-body-text;
  @include text-link-style(
    $txt-color: $cr19-text,
    $txt-uppercase: false,
    $default-underline: false,
    $underline-on-hover-color: $cr19-text,
    $underline-on-focus-color: $cr19-text,
    $txt-underline-offset: 0.313rem
  );
}
// Link style 2 bold - Dark, underline on hover
@mixin text-link--style-2-bold {
  @include text-body-text($font-family: $helvetica-bold-font-family);
  @include text-link-style(
    $txt-color: $cr19-text,
    $txt-uppercase: false,
    $default-underline: false,
    $underline-on-hover-color: $cr19-text,
    $underline-on-focus-color: $cr19-text,
    $txt-underline-offset: 0.313rem
  );
}
// Link - Style 3 - Grey, underline on hover
@mixin text-link--style-3 {
  @include text-body-text;
  @include text-link-style(
    $txt-color: $color-cl-dark-grey,
    $txt-uppercase: false,
    $default-underline: false,
    $underline-on-hover-color: $color-cl-dark-grey,
    $underline-on-focus-color: $color-cl-dark-grey,
    $txt-underline-offset: 0.313rem
  );
}
// Link - Style 4 - Grey with underline
@mixin text-link--style-4 {
  @include text-special-body;
  @include text-link-style(
    $txt-color: $color-cl-dark-grey,
    $txt-uppercase: true,
    $default-underline: true,
    $underline-on-hover-color: $color-light-grey,
    $underline-on-focus-color: $color-grey,
    $txt-underline-offset: 0.313rem
  );
}
// Link - Style 5 - White with underline (for use on dark background)
@mixin text-link--style-5 {
  @include text-special-body;
  @include text-link-style(
    $txt-color: $color-white,
    $txt-uppercase: true,
    $default-underline: true,
    $underline-on-hover-color: $color-white-6opacity,
    $underline-on-focus-color: $color-white-4opacity,
    $txt-underline-offset: 0.313rem
  );
}
// Link - Style 6 - White, underline on hover (for use on dark background)
@mixin text-link--style-6 {
  @include text-body-text;
  @include text-link-style(
    $txt-color: $color-white,
    $txt-uppercase: false,
    $default-underline: false,
    $underline-on-hover-color: $color-white,
    $underline-on-focus-color: $color-white,
    $txt-underline-offset: 0.313rem
  );
}
// Link - Style 7 - Helvetica with underline
@mixin text-link--style-7 {
  @include text-body-text;
  @include text-link-style(
    $txt-color: $cr19-text,
    $txt-uppercase: false,
    $default-underline: true,
    $underline-on-hover-color: $cr19-text-grey,
    $underline-on-focus-color: $color-grey,
    $txt-underline-offset: 0.094rem
  );
}
// Link - Style 8 - Helvetica with underline (for use on dark background)
@mixin text-link--style-8 {
  @include text-body-text;
  @include text-link-style(
    $txt-color: $color-white,
    $txt-uppercase: false,
    $default-underline: true,
    $underline-on-hover-color: $color-white-6opacity,
    $underline-on-focus-color: $color-white-4opacity,
    $txt-underline-offset: 0.094rem
  );
}

// Dropdown - default
@mixin dropdown-opt {
  background: url('#{$base-theme-path}img/icons/src/arrow-8.svg') no-repeat center;
  background-size: 10px;
  background-position: $rdirection 0.7em top 50%, 0 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  border-radius: inherit;
  border: 1px solid $color-black;
  color: $cr19-text;
  font-family: $helvetica-regular-font-family;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  height: 45px;
  padding: 0 15px;
  text-align: $ldirection;
  text-transform: capitalize;
  width: 50%;
}

// Gnav text link - sets a default size
@mixin gnav-text-link {
  font-family: $helvetica-regular-font-family;
  font-size: 16px;
  letter-spacing: 0;
  line-height: get-line-height(16px, 24px);
}

// 2019 Redesign
// Container related rules
@mixin container-max-width-full {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: 100%;
  width: 100%;
}

@mixin container-max-width-x-large {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: $cr19-max-width-xlarge;
  width: 100%;
}

@mixin container-max-width-large {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: $cr19-max-width-large;
  width: 100%;
}

@mixin container-max-width-medium {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: $cr19-max-width-medium;
  width: 100%;
}

@mixin container-max-width-small {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: $cr19-max-width-small;
  width: 100%;
}

@mixin container-max-width-x-small {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: $cr19-max-width-xsmall;
  width: 100%;
}

@mixin container-padding-rules {
  padding-#{$ldirection}: $cr19-pad-xxsmall;
  padding-#{$rdirection}: $cr19-pad-xxsmall;
  width: 100%;
  @media #{$cr19-xsmall-up} {
    padding-#{$ldirection}: $cr19-pad-xsmall;
    padding-#{$rdirection}: $cr19-pad-xsmall;
  }
  @media #{$cr19-small-up} {
    padding-#{$ldirection}: $cr19-pad-small;
    padding-#{$rdirection}: $cr19-pad-small;
  }
  @media #{$cr19-medium-up} {
    padding-#{$ldirection}: $cr19-pad-medium;
    padding-#{$rdirection}: $cr19-pad-medium;
  }
  @media #{$cr19-large-up} {
    padding-#{$ldirection}: $cr19-pad-large;
    padding-#{$rdirection}: $cr19-pad-large;
  }
  @media #{$cr19-xlarge-up} {
    padding-#{$ldirection}: $cr19-pad-xlarge;
    padding-#{$rdirection}: $cr19-pad-xlarge;
  }
}

// 2019 Redesign
// Gmav - style selection options
@mixin gnav-offer-bar-color(
  $bg-offer: $cr19-bg-black,
  $text-color-offer: $cr19-text-white,
  $inactive-color-link: $cr19-text-inactive
) {
  .gnav-header-formatter__top {
    background-color: $bg-offer;
    color: $text-color-offer;
    // @todo redo this inside the components, need to use link mixins
    // using dark bg for 2019 redesign launch
    /*
    .header-gnav-account-link__loyalty-link,
    .header-gnav-account-link__text,
    span,
    a {
      color: $text-color-offer;
      &:hover {
        color: $text-color-offer;
        &:after {
          border-bottom: 1px solid $text-color-offer;
        }
      }
      &.inactive { // @todo remove this
        color: $inactive-color-link;
        &:hover {
          color: $inactive-color-link;
          &:after {
            border-bottom: 0;
          }
        }
      }
    }
    */
    .svgicon {
      fill: $text-color-offer;
    }
  }
}

@mixin gnav-navigation-bar-color(
  $bg-gnav: $cr19-bg-white,
  $text-color-gnav: $color-cl-light-black
) {
  .gnav-header-formatter__gnav {
    background-color: $bg-gnav;
    color: $text-color-gnav;
    span,
    a {
      // @todo update to use link mixins
      color: $text-color-gnav;
    }
    .svgicon {
      fill: $text-color-gnav;
    }
  }
}

// 2019 Redesign
// Grid related
// @todo do something with this or delete it :).

// xx-Small
// 380px: full
// outer margin: 20px x 2
// interior: 340px
// inner col: 40px x 6
// inner gutter: 20px x 5
// outer margin { width: 5.263157894736842%; } /* 20 / 380 */
// column { width: 10.526315789473684%; } /* 40 / 380 */
// gutter { width: 5.263157894736842%; } /* 20 / 380 */

// x-Small
// 540px: full
// outer margin: 20px x 2
// interior: 500px
// inner col: 64px x 6
// inner gutter: 20px x 5
// outer margin { width: 3.7037037037037035%; } /* 20 / 540 */
// column { width: 11.851851851851852%; } /* 64 / 540 */
// gutter { width: 3.7037037037037035%; } /* 20 / 540 */

// Small
// 768px: full
// outer margin: 28px x 2
// interior: 712px
// inner col: 68px x 8
// inner small gutter: 20px x 11
// outer margin { width: 3.6458333333333336%; } /* 28 / 768 */
// column { width: 8.854166666666667%; } /* 68 / 768 */
// gutter { width: 2.6041666666666668%; } /* 20 / 768 */

// Medium
// 1024px: full
// outer margin: 44px x 2
// interior: 936px
// inner col: 56px x 12
// inner small gutter: 24px x 11
// outer margin { width: 4.296875%; } /* 44 / 1024 */
// column { width: 5.46875%; } /* 56 / 1024 */
// gutter { width: 2.34375%; } /* 24 / 1024 */

// Large
// 1304px: full
// outer margin: 64px x 2
// interior: 1176px
// inner col: 76px x 12
// inner small gutter: 24px x 11
// outer margin { width: 4.9079754601226995%; } /* 64 / 1304 */
// column { width: 5.828220858895705%; } /* 76 / 1304 */
// gutter { width: 1.8404907975460124%; } /* 24 / 1304 */

// XL
// 1796px: full
// outer margin: 72px x 2
// interior 1652px
// inner col: 112px x 12
// inner small gutter 28px x 11
// outer margin { width: 4.00890868596882%; } /* 72 / 1796 */
// column { width: 6.2360801781737196%; } /* 112 / 1796 */
// gutter { width: 1.5590200445434299%; } /* 28 / 1796 */

// 2019 Redesign
// Slick arrow placement in padded content margin
@mixin slick-previous-position($icon-size: 24px) {
  #{$ldirection}: -($icon-size);
  @media #{$cr19-xsmall-up} {
    #{$ldirection}: -($cr19-pad-xsmall / 2 + ($icon-size / 2));
  }
  @media #{$cr19-small-up} {
    #{$ldirection}: -($cr19-pad-small / 2 + ($icon-size / 2));
  }
  @media #{$cr19-medium-up} {
    #{$ldirection}: -($cr19-pad-medium / 2 + ($icon-size / 2));
  }
  @media #{$cr19-large-up} {
    #{$ldirection}: -($cr19-pad-large / 2 + ($icon-size / 2));
  }
  @media #{$cr19-xlarge-up} {
    #{$ldirection}: -($cr19-pad-xlarge / 2 + ($icon-size / 2));
  }
}

@mixin slick-next-position($icon-size: 24px) {
  #{$rdirection}: -($icon-size);
  @media #{$cr19-xsmall-up} {
    #{$rdirection}: -($cr19-pad-xsmall / 2 + ($icon-size / 2));
  }
  @media #{$cr19-small-up} {
    #{$rdirection}: -($cr19-pad-small / 2 + ($icon-size / 2));
  }
  @media #{$cr19-medium-up} {
    #{$rdirection}: -($cr19-pad-medium / 2 + ($icon-size / 2));
  }
  @media #{$cr19-large-up} {
    #{$rdirection}: -($cr19-pad-large / 2 + ($icon-size / 2));
  }
  @media #{$cr19-xlarge-up} {
    #{$rdirection}: -($cr19-pad-xlarge / 2 + ($icon-size / 2));
  }
}

@mixin soundIcon($color: $color-black, $size: 45px, $border: $size / 16) {
  .sound-icon {
    width: $size;
    height: $size;
    position: relative;
    cursor: pointer;
    display: inline-block;
    @supports (-webkit-overflow-scrolling: touch) {
      display: none;
    }
    &__control {
      color: $color;
      width: 75%;
      height: 100%;
      line-height: 100%;
      font-size: $size;
      display: block;
      margin: auto;
      svg {
        width: 0.42 * $size;
      }
    }
    &__wave {
      position: absolute;
      border: $border solid transparent;
      border-#{$rdirection}: $border solid $color;
      border-radius: 50%;
      transition: all 200ms;
      margin: auto;
      top: 0;
      bottom: 0;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      &--one {
        width: 50%;
        height: 50%;
        transition: all 0.2s cubic-bezier(0.26, -0.01, 0, 1.28);
      }
      &--two {
        width: 75%;
        height: 75%;
        transition: all 0.2s cubic-bezier(0.26, 0.1, 0, 1.49);
      }
    }
    &--mute .sound-icon__wave {
      border-radius: 50%;
      width: 0%;
      height: 0%;
      border-width: 0 $border 0 0;
      &--one {
        transform: translateX(-10px);
      }
      &--two {
        transform: translateX(-10px);
      }
    }
  }
}

@mixin a-button-base {
  font-family: $base-button-font-family;
  background-color: $color-btn-green;
  text-transform: uppercase;
  color: white;
  line-height: 1.5;
  font-size: 0.9em;
  text-align: center;
  letter-spacing: 0;
  border: 0;
  padding: 4px 10px 3px;
  display: inline-block;
  zoom: 1;
  // *display: inline;
  /* white-space: nowrap */
  @if $cr19 == true {
    background-color: $cr19-bg-black;
    color: $cr19-text-white;
  }
}

@mixin a-button-secondary {
  background-color: $color-btn-inverse;
  color: $color-btn-green;
  border: 1px solid $color-btn-green;
  @if $cr19 == true {
    background-color: $color-white;
    border-color: $cr19-border-light;
    color: $cr19-text;
  }
  &:hover,
  &.hover {
    background-color: $color-btn-darker-inverse;
    @if $cr19 == true {
      background-color: $color-white;
      border-color: $cr19-text;
      color: $cr19-text;
    }
  }
}

@mixin a-button {
  @include a-button-base;
  &.button-secondary {
    @include a-button-secondary;
  }
  &.pink {
    background-color: $color-btn-pink;
  }
  &.green {
    background-color: $color-btn-green;
    @if $cr19 == true {
      background-color: $cr19-bg-black;
      color: $cr19-text-white;
    }
  }
  &.purple {
    background-color: $color-btn-purple;
  }
  &.orange {
    background-color: $color-btn-orange;
  }
  &:hover,
  &.hover,
  &.disabled {
    text-decoration: none;
    background: $color-btn-darker-green;
    @if $cr19 == true {
      background-color: $cr19-bg-med;
      color: $cr19-text-white;
    }
  }
  &.cancel {
    background-color: $color-white;
    border: 1px solid $color-btn-green;
    color: $color-btn-green;
    @if $cr19 == true {
      background-color: $color-white;
      border-color: $cr19-border-light;
      color: $cr19-text;
    }
  }
}

@mixin button {
  font-family: #{$helvetica-bold-font-family};
  text-transform: uppercase;
  line-height: 1.5;
  font-size: 0.9em;
  text-align: center;
  letter-spacing: 0;
  padding: 4px 10px 3px;
  display: inline-block;
  zoom: 1;
  cursor: pointer;
}

@mixin button-cancel-offer {
  background-color: #{$color-white};
  border: 1px solid #{$color-black};
  color: #{$color-black};
}

@mixin productfull-bold-text {
  .elc-product-full {
    .elc-product-display-name-link,
    .elc-product-original-price {
      font-weight: 700;
    }
    .elc-product-tax-included-wrapper {
      .elc-product-tax-included-label-wrapper {
        font-weight: 700;
      }
    }
    .elc-product-overview,
    .elc-product-ingredients,
    .elc-quantity-selector-button {
      h2,
      h5,
      .elc-quantity-selector-label {
        font-weight: 700;
      }
    }
    &-sticky-menu {
      .elc-product-original-price {
        font-weight: 700;
      }
    }
  }
  .elc-product-cross-sell-header {
    font-weight: 700;
  }
}

@mixin hide-mpp-ppu {
  .elc-grid-item-featured-product,
  .elc-grid-item-product {
    .elc-product-price-per-unit-row-wrapper {
      display: none;
    }
  }

  .elc-grid-container {
    .elc-product-name-section {
      .elc-clickable-wrapper {
        .elc-product-price-per-unit-row-wrapper {
          display: none;
        }
      }
    }
  }
}

@mixin clear-pay-ordering {
  .elc-product-installments-price-label {
    order: -1;
  }
}

@mixin blog-wrapper-content {
  &:first-letter {
    float: $ldirection;
    font-family: $helvetica-bold-font-family;
    font-size: 60px;
    line-height: 0.55;
    padding-#{$rdirection}: 7px;
    padding-top: 0.25em;
  }
}

@mixin findations {
  .elc-third-party-tout {
    &__media-wrapper {
      width: 50%;
      @media #{$cr19-large-up} {
        width: 16%;
        margin-top: 3px;
      }
    }
    &-content-below-block {
      font-size: 16px;
      padding-top: 8px;
      @media #{$cr19-large-up} {
        @include swap_direction(padding, 0 0 0 15px);
      }
    }
    &-content-below {
      flex-direction: unset;
      display: block;
      @media #{$cr19-large-up} {
        display: flex;
      }
    }
    &-header {
      font-weight: bold;
      @include swap_direction(padding, 0 5px 0 0);
    }
    &-content {
      @include swap_direction(padding, 0 5px 0 0);
      display: inline-block;
    }
    &-findation-cta {
      font-size: 16px;
      padding: 0;
      .elc-media-asset {
        background: none;
        font-family: $helvetica-regular-font-family;
        color: $color-black;
        padding: 0;
        text-transform: capitalize;
        border-bottom: 1px solid $color-black;
        cursor: pointer;
      }
    }
    &__findation-cta-wrapper {
      display: inline-block;
    }
  }
  .elc-size-picker-container + .elc-product-promo-banner-wrapper,
  .elc-sidebar-button-section + .elc-product-promo-banner-wrapper {
    margin: -1px;
    background: $color-white;
  }
  .elc-product-promo-banner-wrapper:has(.third-party-tout__wrapper) {
    order: 12;
    padding: 12px 0;
    @media #{$cr19-large-up} {
      padding: 20px 0;
    }
    .elc-service-content {
      display: block;
      @media #{$cr19-large-up} {
        display: flex;
      }
    }
    p,
    span {
      font-size: 16px;
    }
  }
  .elc-product-images-wrapper {
    min-height: unset;
  }
  #findation-button-iframe {
    display: none !important;
  }
}

@mixin subline_as_product {
  .elc-product-name-wrapper {
    margin: 0;
  }
  .elc-product-display-name-wrapper {
    .elc-product-name-wrapper {
      .elc-product-display-name-link {
        font-size: 18px;
        min-height: auto;
      }
    }
  }
  .elc-product-sub-display-name {
    @include text-title--med--no-xl;
    margin: 5px 0 8px;
    color: $color-cl-light-black;
  }
}
@mixin best-shade-message($type) {
  @if $type == spp {
    .elc-best-shade-message {
      order: 4;
      width: 100%;
      @media #{$cr19-large-up} {
        margin-bottom: 10px;
      }
    }
    .elc-product-shade-sidebar-content-wrapper {
      .elc-best-shade-message {
        @include text-body-text--no-xl;
        margin-bottom: 5px;
        order: unset;
        @media #{$cr19-medium-up} {
          margin-bottom: 0;
        }
      }
    }
  } @else if $type == quickview {
    .elc-best-shade-message {
      @include text-body-text--no-xl;
      margin-top: 5px;
      order: 3;
    }
  }
}

@mixin underlineEffect($color, $color-hover) {
  border-bottom: 1px solid $color;
  padding-bottom: 4px;
  transition: border-bottom-color 0.3s;

  &:hover {
    border-bottom-color: $color-hover;
  }
}

@mixin shade-picker-style {
  .elc-product {
    &-shade-picker {
      &-slider {
        padding: 0;
        .slick-slider {
          .slick-slide {
            margin: 0;
            padding: 0;
          }
          .slick-track {
            padding: 0;
          }
          .slick-current {
            z-index: 1;
            position: relative;
          }
        }
      }
      .elc-single-shade-wrapper {
        @if $plp-redesign-2024 == false {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          padding-bottom: 0;
          margin: 1px 0 0;
          .elc-subtitle--3 {
            display: none;
          }
          > .elc-shade-icon {
            border-radius: inherit;
            height: 26px;
            width: 20%;
            @media #{$cr19-large-up} {
              height: 24px;
            }
          }
        }
      }
      .elc-shade-image {
        @if $plp-redesign-2024 == false {
          border-radius: 0;
          height: 26px;
          margin: 1px 0 0;
          overflow: hidden;
          padding: 0;
          width: 100%;
          @media #{$cr19-large-up} {
            height: 24px;
          }
          svg {
            transform: scale(5.9);
          }
          &.elc-shade-image-selected {
            border: 1px solid $color-black;
            margin: auto;
          }
        }
      }
      .elc-slider-arrow-wrapper {
        @if $plp-redesign-2024 == false {
          top: 8px;
          &.slick-prev {
            #{$ldirection}: 5px;
            #{$rdirection}: auto;
            [dir='rtl'] & {
              #{$ldirection}: auto;
              #{$rdirection}: 5px;
            }
            z-index: 1;
            &::before {
              display: none;
            }
          }
          &.slick-next {
            #{$ldirection}: auto;
            #{$rdirection}: 6px;
            [dir='rtl'] & {
              #{$ldirection}: 6px;
              #{$rdirection}: auto;
            }
            &::before {
              display: none;
            }
          }
          &.slick-disabled {
            display: none;
          }
          .elc-directional-icon {
            width: 13px;
            height: 13px;
            background-color: $color-white;
            @media #{$cr19-large-up} {
              width: 11px;
              height: 11px;
              top: 13px;
            }
          }
        }
      }
    }
    &-name-section {
      .elc-product-name-wrapper {
        margin-bottom: auto;
      }
      .elc-product-display-name-wrapper,
      .elc-product-shades-count-wrapper {
        text-decoration: none;
      }
      .elc-product-display-name-wrapper {
        + .elc-product-prices-wrapper,
        + .elc-product-prices-clickable-wrapper {
          width: 65%;
          @media #{$cr19-large-up} {
            width: 100%;
          }
          .elc-product-prices-wrapper,
          .elc-product-prices-clickable-wrapper {
            .elc-product-price-row-wrapper {
              width: 100%;
              .elc-price-formatted-wrapper {
                display: inline-block;
              }
            }
          }
          + .elc-product-shades-count-wrapper {
            width: 35%;
            @media #{$cr19-large-up} {
              width: 100%;
            }
          }
        }
      }
      .elc-product-prices-wrapper,
      .elc-product-prices-clickable-wrapper,
      .elc-product-shades-count-wrapper {
        display: inline-block;
        width: 40%;
        @media #{$cr19-large-up} {
          display: block;
          width: 100%;
        }
        .elc-product-prices-wrapper {
          width: 100%;
        }
      }
      .elc-product-shades-count-wrapper {
        text-align: #{$rdirection};
        width: 60%;
        @media #{$cr19-large-up} {
          width: 100%;
        }
        .elc-product-shades-count-wrapper {
          width: 100%;
        }
      }
    }
    &-details-section {
      .elc-short-description-container,
      .elc-product-shades-count-wrapper {
        text-decoration: none;
      }
    }
  }
  .elc-slider-arrow-wrapper {
    &.slick-prev {
      [dir='rtl'] & {
        #{$rdirection}: -15px;
        #{$ldirection}: auto;
      }
    }
    &.slick-next {
      [dir='rtl'] & {
        #{$rdirection}: auto;
        #{$ldirection}: -15px;
      }
    }
  }
}

@mixin product-card-style {
  &.has-filters {
    div.elc-product-brief-view {
      @media #{$cr19-large-up} {
        margin-top: 24px;
      }
    }
  }
  .elc-product-brief-view {
    div.elc-grid-item-tout {
      padding: 12px 8px;
      @media #{$cr19-large-up} {
        padding: 32px 12px;
      }
    }
    div.elc-grid-item-product {
      padding: 12px 8px;
      @media #{$cr19-large-up} {
        padding: 32px 12px;
      }
    }
  }
  .elc-bottom-content-section {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 8px;
  }
  .elc-main-content-section {
    display: flex;
    gap: 8px;
    flex-direction: column;
    @media #{$cr19-large-up} {
      min-height: 230px;
    }
    .elc-product-display-name-wrapper {
      margin-top: 8px;
      @media #{$cr19-large-up} {
        margin-top: 0;
      }
    }
    & > .elc-product-display-name-wrapper:first-child {
      margin-top: 16px;
      @media #{$cr19-large-up} {
        margin-top: 70px;
      }
    }
    & > .elc-size-picker-container.single-size:first-child {
      & + .elc-product-display-name-wrapper {
        margin-top: 8px;
        @media #{$cr19-large-up} {
          margin-top: 0;
        }
      }
    }
  }
  .elc-product-name-wrapper,
  .elc-product-short-description-wrapper {
    margin: 0;
  }
  .elc-product-display-name-link {
    @include text-body-text($font-family: $helvetica-bold-font-family);
  }
  .elc-product-short-description-wrapper {
    @include text-body-text;
    color: $color-input-gray;
  }
  .elc-product-display-name-wrapper,
  .elc-short-description-container,
  .elc-product-prices-clickable-wrapper,
  .elc-shade-swatches-wrapper {
    text-align: center;
  }
  .elc-shade-swatches-wrapper {
    & + .elc-size-picker-container {
      display: none;
    }
  }
  .elc-add-to-bag-sku-inventory-status-message {
    margin: 0;
    &-message {
      @include text-body-text--fine($font-family: $helvetica-bold-font-family);
      color: $cr19-text;
      line-height: 1.4;
      text-align: center;
      @media #{$cr19-large-up} {
        line-height: 1.4;
      }
    }
  }
}

@mixin rating-and-review-style {
  .elc-rating-component-container {
    width: 100%;
    .elc-product-rating-wrapper {
      justify-content: center;
      .elc-rating:empty {
        margin: 3px 0;
        height: $ratingSize;
        position: relative;
        width: $ratingWidth;
        background: url('#{$base-theme-path}img/icons/src/star.svg') repeat-x;
        filter: brightness(0.85);
      }
    }
    .elc-rating-reviews {
      margin-#{$ldirection}: 4px;
      .elc-reviews-number {
        @include text-special-body(
          $font-family: $helvetica-regular-font-family,
        );
      }
    }
    .elc-stars-simplified,
    .elc-stars-simplified-stars {
      margin: 0;
    }
  }
}

@mixin product-atb-style {
  .elc-product-full-cta-wrapper {
    width: 100%;
    button.elc-button.elc-add-to-bag-button,
    button.elc-button.elc-product-notify-me-button {
      @include elc-button--light;
    }
    button.elc-button.elc-add-to-bag-button-disabled {
      @include elc-button--light-disabled;
    }
    button.elc-button.elc-add-to-bag-button,
    button.elc-button.elc-product-notify-me-button,
    button.elc-button.elc-add-to-bag-button-disabled {
      text-transform: none;
      text-decoration: none;
      text-align: center;
      font-size: 1rem;
      line-height: normal;
      @media #{$cr19-large-up} {
        width: 100%;
      }
    }
  }
}

@mixin product-price-style {
  .elc-product-prices-clickable-wrapper {
    order: unset;
    @media #{$cr19-large-up} {
      margin: 0;
      width: 100%;
    }
    .elc-product-prices-wrapper {
      @media #{$cr19-large-up} {
        width: 100%;
        margin: 8px 0;
      }
      .elc-product-price-row-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        gap: 12px;
      }
      .elc-price-formatted {
        @include text-body-text($font-family: $helvetica-bold-font-family);
      }
      &:has(.elc-product-price) {
        .elc-product-price-row-wrapper {
          display: flex;
          flex-direction: row-reverse;
        }
        .elc-price-formatted-wrapper {
          .elc-product-price {
            color: $color-product-price-discounted;
          }
          .elc-product-original-price {
            color: $cr19-text;
          }
        }
      }
    }
  }
}

@mixin size-picker-style {
  .elc-size-picker-container {
    order: unset;
    & + .elc-product-display-name-wrapper {
      .sd-search-gnav-input-field & {
        margin-top: 16px;
        @media #{$cr19-large-up} {
          margin-top: 8px;
        }
      }
    }
    &.single-size {
      .elc-selected-size {
        display: block;
        position: relative;
        top: 0;
        border: 1px solid $color-black;
        padding: 5px;
        overflow: visible;
        width: fit-content;
        margin: 16px 0 0;
        @media #{$cr19-large-up} {
          margin: 29px auto 0;
        }
      }
    }
    .elc-selected-size-space-row {
      display: flex;
      justify-content: center;
    }
    .slick-slider {
      margin: 0;
    }
    div.elc-slider-view-wrapper {
      margin: 16px 0 0;
      @media #{$cr19-large-up} {
        margin: 28px 0 0;
      }
      .slick-prev {
        #{$ldirection}: -5px;
      }
      .slick-next {
        #{$rdirection}: -5px;
      }
      .slick-list {
        margin: 0 24px;
      }
      .slick-track {
        /* stylelint-disable-next-line declaration-no-important */
        width: max-content !important;
      }
      .elc-slider-arrow-wrapper {
        top: 10px;
        &::before {
          display: none;
        }
        .elc-icon.elc-directional-icon {
          mask: url('#{$img_base_path}/icons/src/arrow-12.svg') center center no-repeat;
          width: 30px;
          height: 30px;
          transform: none;
        }
        .elc-slider-arrow-left.elc-directional-icon {
          transform: rotate(180deg);
        }
      }
      .elc-size-picker-box,
      .elc-size-picker-box-selected {
        @include text-special-body(
          $font-family: $helvetica-regular-font-family,
        );
        border: 1px solid $color-light-gray;
        color: $color-input-gray;
        margin-#{$rdirection}: 12px;
        padding: 5px 12px;
        @media #{$cr19-large-up} {
          @include swap_direction(margin, 0 5px 0 1px);
        }
      }
      .elc-size-picker-box-selected {
        border: 1px solid $color-black;
        color: $color-black;
      }
    }
  }
}

@mixin shade-picker-style-v2 {
  .elc-product {
    &-shade-picker {
      .slick-slider {
        margin: 0;
      }
      .elc-single-shade-wrapper {
        display: flex;
        flex-direction: column-reverse;
        padding: 0;
        @media #{$cr19-large-up} {
          padding-bottom: 3px;
        }
        .elc-shade-icon {
          border-radius: 0;
          height: 24px;
          width: 24px;
          box-shadow: 0 0 0 2px $color-white, 0 0 0 3px $color-black;
          margin-top: 3px;
        }
        p {
          @include text-special-body($font-family: $helvetica-regular-font-family);
          margin: 16px 0 8px;
          line-height: normal;
          @media #{$cr19-large-up} {
            margin: 8px 0 0;
          }
        }
      }
      &-title {
        @include text-special-body($font-family: $helvetica-regular-font-family);
        line-height: normal;
        margin: 16px 0 8px;
        @media #{$cr19-large-up} {
          margin: 8px 0 0;
        }
      }
      &-slider {
        padding: 0;
        .slick-slider {
          .slick-slide {
            margin: 0;
            padding: 0;
          }
          .slick-track {
            padding: 0;
            margin: auto;
          }
          .slick-current {
            z-index: 1;
            position: relative;
          }
        }
      }
      .elc-shade-image {
        border-radius: 0;
        width: 24px;
        height: 24px;
        overflow: hidden;
        margin: 0;
        svg {
          width: 36px;
          height: 36px;
          margin: -6px;
        }
        &.elc-shade-image-selected {
          box-shadow: 0 0 0 2px $color-white, 0 0 0 3px $color-black;
        }
      }
      .elc-slider-arrow-wrapper {
        top: 2px;
        opacity: 1;
        @media #{$cr19-large-up} {
          top: 0;
        }
        .elc-icon.elc-directional-icon {
          mask: url('#{$img_base_path}/icons/src/arrow-12.svg') center center no-repeat;
          width: 30px;
          height: 30px;
          transform: none;
        }
        .elc-slider-arrow-left.elc-directional-icon {
          transform: rotate(180deg);
        }
        &.slick-prev {
          z-index: 1;
          #{$ldirection}: 0;
          #{$rdirection}: auto;
          [dir='rtl'] & {
            #{$ldirection}: auto;
            #{$rdirection}: 0;
          }
        }
        &.slick-next {
          #{$ldirection}: auto;
          #{$rdirection}: 0;
          [dir='rtl'] & {
            #{$ldirection}: 0;
            #{$rdirection}: auto;
          }
        }
        &.slick-prev::before,
        &.slick-next::before {
          display: none;
        }
        &.slick-disabled {
          .elc-directional-icon {
            background-color: $color-light-gray;
          }
        }
      }
      .slick-list {
        margin: 0 20px;
        padding-top: 3px;
        @media #{$cr19-large-up} {
          margin: 0 22px;
        }
      }
    }
  }
}

@mixin quickshop {
  .elc-product-brief {
    .elc-product-quick-view-button-wrapper {
      position: relative;
      height: 0;
      .elc-product-quick-view-button {
        position: absolute;
        bottom: 8px;
        #{$rdirection}: 8px;
        background-color: $color-white;
        font-weight: bold;
        width: auto;
        letter-spacing: 1.25px;
        display: none;
        @media #{$cr19-large-up} {
          [dir='rtl'] & {
            margin: 0;
          }
        }
      }
    }
    &:hover {
      .elc-product-quick-view-button {
        display: block;
      }
    }
  }
}

@mixin ellipsisMultilineText($lines-to-display) {
  display: -webkit-box;
  -webkit-line-clamp: $lines-to-display;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// Outline style on focused elements for keyboard accessibility.
@mixin outline {
  outline: 1px solid $color-gray-dusty !important;
}

@mixin linkStyleForButton() {
  background: none;
  padding: 0;
  text-transform: none;
  border: none;
}

@mixin heroBlockVerticalAlignment(
  $margin,
  $margin-xsmall,
  $margin-small,
  $margin-medium,
  $margin-large,
  $margin-xlarge
) {
  margin: $margin;
  @media #{$cr19-xsmall-up} {
    margin: $margin-xsmall;
  }
  @media #{$cr19-small-up} {
    margin: $margin-small;
  }
  @media #{$cr19-medium-up} {
    margin: $margin-medium;
  }
  @media #{$cr19-large-up} {
    margin: $margin-large;
  }
  @media #{$cr19-xlarge-up} {
    margin: $margin-xlarge;
  }
}
///
/// Triangles - creates right triangles with border-clipping tricks.
///
/// Adapted from this: http://codepen.io/vieron/pen/xuHDA
///
/// Usage:
/// @include triangle(size, color, direction)
///
/// size: The length of the hypotenuse of the triangle
/// color: The fill color of the triangle
/// direction: Which way the arrow points
///
@mixin triangle($size, $color, $direction) {
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: $size / 2;
  @if $direction == up {
    border-bottom-color: $color;
  } @else if $direction == right {
    border-left-color: $color;
  } @else if $direction == down {
    border-top-color: $color;
  } @else if $direction == left {
    border-right-color: $color;
  }
}

// checkout related mixins

// secondary header/title style
@mixin checkout-secondary-heading {
  font-family: $main-font;
  font-size: get-rem(24px);
  font-weight: 700;
  letter-spacing: 0;
  line-height: get-line-height(30px, 32px);
}

// sub header/title style - medium version
@mixin checkout-sub-heading--medium {
  font-family: $helvetica-light-font-family;
  font-size: get-rem(16px);
  font-weight: 700;
  letter-spacing: 0;
  line-height: get-line-height(18px, 24px);
}

// sub header/title style - small version - style 1
@mixin checkout-sub-heading--small {
  font-family: $main-font;
  font-size: get-rem(12px);
  line-height: get-line-height(12px, 18px);
}

// sub header/title style - small version - style 2
@mixin checkout-sub-heading--small-style2 {
  font-family: $bold-font-family;
  font-size: get-rem(12px);
  letter-spacing: 1.7px;
  line-height: get-line-height(12px, 20px);
  text-transform: uppercase;
}

// default styling for paragraphs/body text
@mixin checkout-body-text--medium {
  font-family: $helvetica-light-font-family;
  font-size: get-rem(14px);
  letter-spacing: 0;
  line-height: get-line-height(14px, 24px);
}

// default styling for paragraphs/body text bold
@mixin checkout-body-text--bold {
  font-family: $bold-font-family;
  font-size: get-rem(14px);
  letter-spacing: 0;
  line-height: get-line-height(14px, 24px);
}

// default styling for smaller paragraphs/body text
@mixin checkout-body-text--small {
  font-family: $helvetica-light-font-family;
  font-size: get-rem(12px);
  line-height: get-line-height(12px, 14px);
}

// animations
@mixin shimmer($duration: 1s, $easing: linear) {
  animation: elc-shimmer $duration 60 $easing;
  backface-visibility: hidden;
  background: $color-shimmer-grey-light
    linear-gradient(
      -45deg,
      $color-shimmer-grey-light 0%,
      $color-shimmer-grey-light 40%,
      $color-shimmer-grey 50%,
      $color-shimmer-grey-light 60%,
      $color-shimmer-grey-light 100%
    );
  background-size: 250% 250%;
  perspective: 1000px;
  transform: rotateZ(360deg);
  will-change: transform;
  [dir='rtl'] & {
    animation: elc-shimmer-rtl $duration 60 $easing;
    backface-visibility: hidden;
    background: $color-shimmer-grey-light
      linear-gradient(
        45deg,
        $color-shimmer-grey-light 0%,
        $color-shimmer-grey-light 40%,
        $color-shimmer-grey 50%,
        $color-shimmer-grey-light 60%,
        $color-shimmer-grey-light 100%
      );
    background-size: 250% 250%;
    perspective: 1000px;
    transform: rotateZ(360deg);
    will-change: transform;
  }
}

@mixin fade-in($duration: 1s, $easing: linear) {
  animation: fade-in $duration $easing forwards;
}

@mixin dropdown-base($width: 340px) {
  & + .sbHolder {
    border: 1px solid $color-black;
    height: 45px;
    background: $color-white;
    width: $width;

    .sbToggle {
      height: 100%;
      background: url(/media/export/images/mobile_sku_arrow_dn.png) 18px 18px no-repeat;
      opacity: 1;
      width: 43px;

      &:focus {
        outline: 1px solid $color-black;
      }
    }

    .sbToggleOpen {
      background: url(/media/export/images/mobile_sku_arrow_up.png) 18px 18px no-repeat;
    }

    .sbSelector {
      @include text-body-text;
      height: 100%;
      line-height: 2.6875rem;
      text-transform: capitalize;
      padding-#{$ldirection}: 1rem;
      text-indent: 0;
      @media #{$cr19-typography-large-up} {
        font-size: 1rem;
        line-height: 2.6875rem;
      }

      &:focus {
        outline: 1px solid $color-black;
      }
    }

    .sbOptions {
      box-sizing: content-box;
      width: 100%;
      background-color: $color-white;
      border: 1px solid $color-grey;
      border-top: 1px solid $color-black;

      li {
        border-bottom: 1px solid $color-grey;
        padding-#{$ldirection}: 0;

        a {
          @include text-body-text;
          @include swap_direction(padding, 0 0 0 1rem);
          height: 2.8125rem;
          line-height: 2.8125rem;
          @media #{$cr19-typography-large-up} {
            font-size: 1rem;
            line-height: 2.8125rem;
          }

          &:hover {
            background: $cr19-bg-light;
          }
        }
      }
    }
  }

  &:disabled + .sbHolder {
    pointer-events: none;
    border: 1px solid $color-grey;

    .sbSelector {
      color: $color-light-gray;
    }

    .sbToggle {
      display: none;
    }
  }
}

@mixin dropdown-small--base {
  @include dropdown-base();
}

@mixin dropdown-large--base {
  @include dropdown-base(276px);
}

@mixin dropdown-large-mini--base {
  @include dropdown-base(132px);
}

@mixin dropdown-wrapper-base($size) {
  label {
    margin-bottom: 4px;
    display: block;
  }
  select {
    @if $size == small {
      @include dropdown-small--base;
    } @else if $size == large {
      @include dropdown-large--base;
    } @else if $size == large-mini {
      @include dropdown-large-mini--base;
    }
  }
}

@mixin dropdown-small {
  @include dropdown-wrapper-base(small);
}

@mixin dropdown-large {
  @include dropdown-wrapper-base(large);
}

@mixin dropdown-large-mini {
  @include dropdown-wrapper-base(large-mini);
}

@mixin courtesy-nav-separator {
  & ~ &::before {
    @media #{$cr19-large-up} {
      content: '|';
      clear: both;
      display: inline-block;
      padding: 0 18px;
      color: $color-grey;
    }
  }
}

@mixin productShadePicker {
  .elc-product-shade-picker {
    width: 100%;
    .elc-product-shade-groups {
      border-bottom: 1px solid $color-grey;
      & + .elc-product-shade-picker-extra-large,
      & + .elc-product-shade-picker-mobile {
        margin-top: 12px;
      }
      .slick-slide {
        padding-#{$rdirection}: 16px;
        &:last-child {
          padding: 0;
        }
      }
      .elc-slider-view-wrapper {
        padding: 0;
      }
      .elc-product-shade-group-link {
        @include text-body-text;
        border: none;
        background: transparent;
        padding: 10px 12px;
        color: $color-input-gray;
        line-height: 1.2rem;
        @media #{$cr19-typography-large-up} {
          line-height: 1.2rem;
        }
        &.active {
          color: $color-black;
          @include text-body-text($font-family: $helvetica-bold-font-family);
          border-bottom: 1px solid $color-black;
        }
      }
      .slick-slider {
        margin: 0;
      }
    }
    .elc-shade-image-selected {
      box-shadow: 0 0 0 1px $color-black;
      border-radius: unset;
    }
    .elc-absolute-shade-wrapper {
      position: relative;
    }
    .elc-product-shades-grid-toggle {
      display: none;
    }
    .elc-product-shades-container {
      overflow: visible;
      max-height: 100%;
    }
    .elc-product-shades-grid-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(30px, 1fr));
      grid-auto-flow: dense;
      grid-gap: 16px 20px;
      margin: 0;
    }
    .elc-shade-image-wrapper {
      flex: 1;
      display: inline-flex;
      max-width: 32px;
      max-height: 32px;
    }
    .elc-shade-image {
      width: 32px;
      height: 32px;
      margin: 0;
      padding: 4px;
      margin-#{$ldirection}: -4px;
      &.elc-status-sold-out {
        &::after {
          position: absolute;
          width: 50px;
          #{$ldirection}: -5px;
          height: 45px;
          top: -2px;
          /* stylelint-disable function-comma-space-after */
          background: linear-gradient(
            -45deg,
            transparent 50%,
            $color-white 50%,
            $color-white calc(50% + 1.2px),
            transparent calc(50% + 1.2px)
          );
        }
      }
    }
    .elc-grid-column {
      padding: 20px 0 0;
    }
    .elc-product-shade-picker-title {
      @include text-special-body;
    }
    svg {
      clip-path: inset(15%);
      width: 46px;
      height: 46px;
      margin: -7px;
    }
  }
  .multiple-sizes ~ .elc-product-shade-picker {
    display: none;
  }
}

@mixin productShadeDropdown {
  .elc-shades-dropdown-component {
    width: 50%;
    height: 48px;
    @media #{$cr19-large-up} {
      width: 62%;
    }
    &:not(:has(+ [data-rendered-view='vto-cta-button'])) {
      width: 100%;
    }
    & + .elc-service-view-renderer {
      width: 50%;
      padding-#{$ldirection}: 10px;
      @media #{$cr19-large-up} {
        width: 38%;
      }
      .elc-vto-button {
        width: 100%;
        &:disabled {
          display: block;
        }
      }
    }
    .elc-icon-wrapper {
      margin: 0 2px;
    }
    .elc-product-shade-picker-dropdown {
      .elc-dropdown-option {
        justify-content: flex-start;
        padding: 12px;
        align-items: center;
        gap: 12px;
        .elc-product-shade-picker-dropdown-shade-hex {
          width: 24px;
          height: 24px;
        }
      }
    }
    .elc-product-shade-picker-dropdown-shade-hex {
      border-radius: inherit;
      height: 32px;
      width: 32px;
      overflow: hidden;
      svg {
        transform: scale(1.4);
      }
    }
    .elc-dropdown-options {
      position: relative;
    }
    .elc-dropdown-options-list {
      position: relative;
      max-height: 200px;
      overflow: scroll;
      border: 1px solid $color-grey;
      border-top: none;
    }
    .elc-dropdown-input-wrapper {
      border: 1px solid $color-black;
      height: 48px;
      padding-#{$ldirection}: 12px;
    }
    .elc-dropdown-readonly-input {
      padding-#{$ldirection}: 15px;
    }
  }
}

@mixin productSizeChips {
  .elc-size-picker-container {
    &.single-size {
      display: none;
    }
    &.multiple-sizes {
      width: 100%;
      margin: 0;
      padding: 0;
      .elc-selected-size-space-row {
        display: none;
      }
    }
    .elc-slider-view-wrapper {
      padding: 0;
      .slick-slider {
        margin: 0;
      }
      .slick-track {
        margin: 0 -4px;
        flex-wrap: wrap;
        justify-content: start;
      }
      .slick-slide {
        /* stylelint-disable-next-line declaration-no-important */
        width: auto !important;
        padding: 0 4px 16px;
        flex-basis: 50%;
        flex-grow: 1;
        @media #{$cr19-large-up} {
          flex-grow: 0;
        }
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
    .elc-size-picker-box,
    .elc-size-picker-box-selected {
      width: 100%;
      margin: 0;
      padding: 14px 16px;
      border: 1px solid $color-light-grey;
      border-radius: unset;
      color: $color-cl-light-black;
      text-align: $ldirection;
    }
    .elc-size-picker-box-selected {
      background-color: $color-white;
      border-color: $color-cl-light-black;
      color: $color-cl-light-black;
    }
  }
}

@mixin productImageCarousel {
  .elc-carousel-section-wrapper {
    @media #{$cr19-medium-up} {
      display: flex;
    }
    .elc-carousel-thumbnails {
      display: inline-block;
      width: 86px;
      margin: 0;
      .slick-track {
        @media #{$cr19-large-up} {
          max-height: fit-content;
        }
      }
      .elc-slider-arrow-wrapper {
        position: relative;
        #{$ldirection}: 0;
        #{$rdirection}: 0;
        margin: 0;
        padding: 4px 0;
        &::before {
          display: none;
        }
        &.slick-disabled {
          opacity: 1;
          .elc-directional-icon {
            background-color: $color-light-gray;
          }
        }
        &.slick-prev {
          top: 0;
          margin-bottom: 12px;
        }
        &.slick-next {
          bottom: 0;
        }
        .elc-slider-arrow-top,
        .elc-slider-arrow-bottom {
          width: 12px;
          height: 12px;
          margin: auto;
        }
      }
      .slick-slide {
        padding: 1px 0 12px;
        &.slick-current {
          .elc-product-images-wrapper {
            border: 1px solid $color-black;
          }
        }
        /* stylelint-disable-next-line selector-max-type */
        > div > div {
          padding: 0;
        }
      }
      .elc-slider-view-wrapper {
        padding: 0;
      }
      .elc-product-images-wrapper {
        width: 86px;
        min-height: auto;
        .elc-img {
          width: 100%;
          cursor: pointer;
        }
      }
    }
    .elc-carousel-products {
      @media #{$cr19-medium-up} {
        display: inline-block;
        width: calc(100% - 86px);
        padding-#{$ldirection}: 24px;
      }
      .elc-slider-view-wrapper {
        @media #{$cr19-medium-up} {
          padding: 0;
        }
      }
      .slick-slider {
        .slick-list {
          @media #{$cr19-medium-up} {
            width: auto;
            #{$ldirection}: 0;
            margin: 0;
            background-color: transparent;
          }
        }
        .elc-slider-dots-list {
          bottom: 0;
          padding: 12px;
          text-align: center;
          .elc-slider-dot {
            width: auto;
            height: auto;
          }
        }
      }
      .elc-slider-arrow-wrapper {
        &.slick-disabled {
          opacity: 1;
          pointer-events: auto;
          .elc-directional-icon {
            background-color: $color-light-gray;
          }
        }
      }
      .elc-product-badge-wrapper {
        @media #{$cr19-medium-up} {
          top: 12px;
          #{$ldirection}: 18px;
        }
        .elc-product-badge {
          text-transform: lowercase;
          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
      .elc-product-images-wrapper {
        @media #{$cr19-medium-up} {
          min-height: auto;
        }
        img {
          @media #{$cr19-large-up} {
            aspect-ratio: 4 / 5;
          }
        }
      }
    }
  }
}

@mixin productAutoReplenish {
  .elc-product-auto-replenish {
    order: unset;
    margin: 0;
    @if $pdp-redesign-2024 == true {
      &:has(.elc-service-view-renderer:empty) {
        display: none;
      }
    }
    .elc-auto-replenish-subscribe {
      &-wrapper {
        .elc-checkbox-label {
          @include text-body-text--small($font-family: $helvetica-bold-font-family);
        }
      }
      &-copy-wrapper {
        @include text-body-text--small;
      }
    }
    .elc-auto-replenish-dropdown {
      .elc-dropdown-readonly-input,
      .elc-dropdown-option {
        @include text-body-text--small;
      }
    }
    @if $pdp-redesign-2024 == true {
      &-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
        flex-wrap: wrap;
      }
      .elc-auto-replenish-dropdown {
        border: 1px solid $color-black;
        height: 44px;
        background: $color-white;
        width: calc(100vw - 40px);
        @media #{$cr19-large-up} {
          width: 100%;
        }
      }
      .elc-auto-replenish-open-modal {
        #{$ldirection}: 85px;
        position: absolute;
        top: 2px;
        @media #{$cr19-large-up} {
          height: 15px;
          #{$ldirection}: 90px;
        }
      }
      .elc-dropdown-readonly-input {
        padding-#{$ldirection}: 12px;
      }
      .elc-auto-replenish-dropdown-wrapper {
        position: relative;
        #{$ldirection}: auto;
        top: 0;
        padding: 0;
        z-index: 9;
        width: 100%;
        display: contents;
        @media #{$cr19-large-up} {
          flex: auto;
          height: auto;
        }
        & > div {
          display: contents;
        }
        .elc-dropdown-input-wrapper {
          width: 100%;
          height: 40px;
          background: none;
        }
        .elc-dropdown-options-list {
          width: 100%;
          max-height: 230px;
          top: 3px;
          @media #{$cr19-large-up} {
            max-height: 214px;
            margin: 0;
          }
        }
      }
      .elc-dropdown-arrow {
        display: block;
      }
      .elc-checkbox-label {
        @include text-body-text--small($font-family: $helvetica-bold-font-family);
      }
      .elc-auto-replenish-subscribe-copy-wrapper {
        @include text-body-text--small;
        padding-#{$ldirection}: 22px;
      }
      .elc-checkbox {
        display: flex;
        align-items: center;
        .elc-checkbox-label {
          height: 21px;
          @media #{$cr19-large-up} {
            height: 16px;
          }
        }
      }
    }
  }
}

@mixin productPriceRow {
  .elc-product-price-row-wrapper {
    display: inline-flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-#{$rdirection}: 10px;
    .elc-price-formatted-wrapper {
      .elc-price-formatted {
        @include text-special--callout;
        text-decoration: line-through;
        color: $cr19-text;
      }
      &:last-child {
        .elc-price-formatted {
          text-decoration: none;
        }
      }
    }
    &:has(.elc-product-price) {
      .elc-price-formatted-wrapper {
        &:last-child {
          .elc-product-price {
            @include text-special--callout;
            color: $color-product-price-discounted;
            padding-#{$rdirection}: 8px;
          }
        }
        .elc-product-original-price {
          @include text-special--callout;
          color: $cr19-text;
        }
      }
    }
  }
}

@mixin productPrice {
  .elc-product-prices-clickable-wrapper {
    width: auto;
    margin: 0;
    .elc-product-prices-wrapper {
      .page-product & {
        padding: 0;
      }
    }
    .elc-product-price-row-wrapper {
      display: flex;
      margin: 0;
      align-items: center;
      .elc-price-formatted-wrapper {
        display: flex;
        &:last-child {
          .elc-price-formatted {
            @include text-special--callout($font-family: $helvetica-bold-font-family);
            color: $color-black;
          }
        }
        .elc-price-formatted {
          @include text-body-text--small;
          margin: auto;
          color: $color-cl-dark-grey;
        }
        .elc-product-original-price {
          padding: 0;
        }
      }
    }
  }
  .elc-size-picker-container {
    &.single-size {
      display: flex;
      margin-#{$ldirection}: 12px;
      padding-#{$ldirection}: 8px;
      border-#{$ldirection}: 1px solid $cr19-border-light;
      width: auto;
      @if $pdp-redesign-2024 == true {
        height: fit-content;
      }
      @media #{$cr19-large-up} {
        @if $pdp-redesign-2024 == true {
          width: auto;
        } @else {
          width: 50%;
        }
      }
      .elc-selected-size-space-row {
        margin: auto 0;
      }
      .elc-size-picker-wrapper {
        min-width: auto;
        margin: auto;
        .elc-selected-size {
          @include text-body-text--small;
          color: $color-cl-dark-grey;
        }
      }
    }
  }
}

@mixin productAfterpay {
  div.elc-product-installments-price {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding-top: 0;
    margin-top: 0;
    align-items: center;
    border: none;
    .elc-product-installments-price-logo {
      width: auto;
      height: 16px;
    }
    .elc-product-installments-price-open-modal {
      margin: 0 8px;
      &::after {
        width: 13px;
        height: 13px;
      }
    }
    .elc-product-installments-price-label,
    .elc-product-installments-price-unavailable-label {
      @include text-body-text--small;
      width: auto;
    }
  }
}

@mixin productQuantitySelector {
  .elc-quantity-selector-button {
    flex-basis: 100%;
    height: 0;
    .elc-quantity-selector-label {
      display: none;
    }
    .elc-item-quantity-spinner-selector {
      width: 108px;
      height: 48px;
      margin: 0;
      border: 1px solid $color-light-grey;
      border-radius: 0;
      align-items: center;
    }
    .elc-spinner-selector-button {
      width: auto;
      margin: auto 10px;
      padding: 0;
      &:disabled {
        .elc-spinner-minus-icon,
        .elc-spinner-plus-icon {
          background-color: $color-light-grey;
        }
      }
      .elc-spinner-minus-icon,
      .elc-spinner-plus-icon {
        width: 16px;
        height: 16px;
        background-color: $color-cl-light-black;
      }
    }
    .elc-spinner-selector-data-wrapper {
      @include text-body-text;
      padding: 0;
    }
  }
}

@mixin productAddToBagButton {
  div.elc-product-full-cta-wrapper {
    @include swap_direction(padding, 0 0 0 20px);
    width: calc(100% - 108px);
    box-shadow: none;
    margin-top: -16px;
    @media #{$cr19-large-up} {
      margin-top: -24px;
    }
    .elc-add-to-bag-button,
    .elc-product-notify-me-button {
      @include elc-button--dark--light-hover;
      height: 48px;
      text-transform: none;
      @media #{$cr19-large-up} {
        width: 100%;
      }
      &.elc-add-to-bag-button-disabled,
      &:disabled {
        @include elc-button--light-disabled;
        @media #{$cr19-large-up} {
          width: 100%;
        }
      }
    }
    .temporarily-out-of-stock {
      height: auto;
    }
  }
  div.elc-add-to-bag-sku-inventory-status-message {
    margin: 0;
    order: unset;
    .elc-add-to-bag-sku-inventory-status-message-message {
      @include text-body-text($font-family: $helvetica-bold-font-family);
    }
  }
}

@mixin productPromoBanner {
  .elc-product-promo-banner-wrapper {
    order: 12;
    @if $pdp-redesign-2024 == false {
      padding: 1rem 0;
      @media #{$cr19-medium-up} {
        padding: 1.5rem 0;
      }
    }
    .elc-basic-tout {
      &__media {
        & ~ .elc-basic-tout__content-over-media-block {
          position: absolute;
          top: 0;
          bottom: 0;
          #{$ldirection}: 0;
          #{$rdirection}: 0;
        }
        .elc-product-images-wrapper {
          min-height: 100%;
        }
      }
      &-content-above,
      &-content-below {
        display: block;
      }
    }
  }
}
